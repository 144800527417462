import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["productDescription", "ecommerceDescription", "seoTextDesc", "productSpecification", "ecommerceSpecification", "descriptionCount", "specificationCount", "ecommerceSpecCount", "ecommerceDescCount", "seoTextCount"]

  connect() {
    if (this.hasProductDescriptionTarget) {
      this.updateWordCount(this.productDescriptionTarget, this.descriptionCountTarget)
      this.productDescriptionTarget.addEventListener("input", () => {
        this.updateWordCount(this.productDescriptionTarget, this.descriptionCountTarget)
      })
    }

    if (this.hasProductSpecificationTarget) {
      this.updateWordCount(this.productSpecificationTarget, this.specificationCountTarget)
      this.productSpecificationTarget.addEventListener("input", () => {
        this.updateWordCount(this.productSpecificationTarget, this.specificationCountTarget)
      })
    }

    if (this.hasEcommerceDescriptionTarget) {
      this.updateWordCount(this.ecommerceDescriptionTarget, this.ecommerceDescCountTarget)
      this.ecommerceDescriptionTarget.addEventListener("input", () => {
        this.updateWordCount(this.ecommerceDescriptionTarget, this.ecommerceDescCountTarget)
      })
    }

    if (this.hasEcommerceSpecificationTarget) {
      this.updateWordCount(this.ecommerceSpecificationTarget, this.ecommerceSpecCountTarget)
      this.ecommerceSpecificationTarget.addEventListener("input", () => {
        this.updateWordCount(this.ecommerceSpecificationTarget, this.ecommerceSpecCountTarget)
      })
    }

    if (this.hasSeoTextDescTarget) {
      this.updateWordCount(this.seoTextDescTarget, this.seoTextCountTarget)
      this.seoTextDescTarget.addEventListener("input", () => {
        this.updateWordCount(this.seoTextDescTarget, this.seoTextCountTarget)
      })
    }
  }

  updateWordCount(textArea, countTarget) {
    const wordLimit = 150;
    const words = textArea.value.split(/\s+/).filter(word => word.length > 0);
    const wordCount = words.length;

    countTarget.textContent = `${wordCount} / ${wordLimit} words`;

    if (wordCount > wordLimit) {
      textArea.value = words.slice(0, wordLimit).join(" ");
      countTarget.textContent = `${wordLimit} / ${wordLimit} words (limit reached)`;
    }
  }
}
