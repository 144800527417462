import { Controller } from "@hotwired/stimulus"
import { Modal } from "bootstrap"

export default class extends Controller {

  static targets = [ "status", "delivery", "overlay", "discharge", "units" ]
  static values = { completeStatus: String, ecommerceRecord: Boolean, dischargeDate: String }

  connect() {
    this.checkCompleteStatus();
    this.modal = new Modal(this.element)
    this.modal.hide()
    this.showDeliveryAttributes();
    this.showItemsAttributes();
  }

  checkCompleteStatus() {
    const ecommerceRecord = this.ecommerceRecordValue;
    const saleCompleteStatus = this.completeStatusValue;

    if (ecommerceRecord == true && saleCompleteStatus === "pending-in-store") {
      this.showOverlay();
    } else if (ecommerceRecord == false && saleCompleteStatus === "") {
      this.showOverlay();
    } else if (ecommerceRecord == false && saleCompleteStatus === "pending-in-store") {
      const dischargeDate = this.dischargeDateValue;
      if (dischargeDate === "") {
        this.showDischargeDateModal();
      }
    }
     else {
      this.hideOverlay();
    }
  }

  showOverlay() {
    this.overlayTarget.classList.remove("d-none");
  }

  hideOverlay() {
    this.overlayTarget.classList.add("d-none");
  }

  dismissModal() {
    this.hideOverlay();
  }

  showDeliveryAttributes(){
    if (this.statusTarget.value === "delivery") {
      this.deliveryTarget.classList.remove("d-none")
    } else {
      this.deliveryTarget.classList.add("d-none")
    }
  }

  showDischargeDateModal() {
    this.dischargeTarget.classList.remove("d-none");
    const modalElement = this.dischargeTarget.querySelector('#dischargeDateModal');
    const modal = new Modal(modalElement);
    modal.show();
  }

  showItemsAttributes() {
    if (this.statusTarget.value === "pending-in-store") {
      this.unitsTarget.classList.remove("d-none");
    } else {
      this.unitsTarget.classList.add("d-none");
    }
  }
}
