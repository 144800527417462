import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = [
    "hasMultipleVariant",
    "variantsSection",
    "multipleVariant",
    "comboSection",
    "stockSection",
    "measurementsSection",
    "supplierSection",
    "productType",
    "buyingPrice",
    "buyingPriceError"
  ];

  static values = {
    warehouses: Array,
    itemizable: String,
  };

  connect() {
    this.initializeTooltips(); // Initialize tooltips
    this.hideAllSections();
    this.toggleSection(this.productTypeTarget.value);
  }

  productTypeChanged(event) {
    this.hideAllSections();
    this.toggleSection(event.target.value);
  }

  // Initialize Bootstrap tooltips for the Dimensions label
  initializeTooltips() {
    const dimensionsLabel = this.measurementsSectionTarget.querySelector('label[for=""]');
    if (dimensionsLabel) {
      dimensionsLabel.querySelector('[data-toggle="tooltip"]').tooltip();
    }
  }

  toggleSection(productType) {
    console.log(productType)
    if (productType == "Combo") {
      this.comboSectionTarget.classList.toggle("d-none");
    } else if (productType == "Standard") {
      this.variantsSectionTarget.classList.toggle("d-none");
      this.stockSectionTarget.classList.toggle("d-none");
      this.measurementsSectionTarget.classList.toggle("d-none");
      this.supplierSectionTarget.classList.toggle("d-none");
    } else if (productType == "Service") {
      this.buyingPriceTarget.value = "0.0";
      this.buyingPriceTarget.disabled = true;
    } else {}
  }

  hideAllSections() {
    this.comboSectionTarget.classList.add("d-none");
    this.variantsSectionTarget.classList.add("d-none");
    this.stockSectionTarget.classList.add("d-none");
    this.measurementsSectionTarget.classList.add("d-none");
    this.supplierSectionTarget.classList.add("d-none");
  }

  get tomSelectController() {
    return this.application.getControllerForElementAndIdentifier(
      this.element,
      "tom-select"
    );
  }

  hasMultipleVariantChange() {
    this.variantsSectionTarget.classList.toggle("d-none");
  }

  addAssociation() {
    var content = this.templateTarget.innerHTML.replace(
      /TEMPLATE_RECORD/g,
      this.itemsTarget.querySelectorAll(".nested-fields").length
    );
    this.templateTarget.insertAdjacentHTML("afterend", content);
  }

  removeAssociation(event) {
    event.preventDefault();
    let item = event.target.closest(".nested-fields");
    item.querySelector("input[name*='_destroy']").value = 1;
    item.style.display = "none";
  }

  multipleVariantChange() {
    var variants = this.tomSelectController.select.items;
    var last_variant = variants.pop();
    if (last_variant) {
      this.warehousesValue.forEach((warehouse, i) => {
        this.addAssociation();
        this.addFieldValues(warehouse, last_variant);
      });
    }
  }
  addFieldValues(warehouse, last_variant) {
    var items = this.itemsTarget.querySelectorAll(".nested-fields");
    var item = items[0];
    item.querySelector("input[name*='warehouse_id']").value = warehouse.id;
    item.querySelector("input[name*='name']").value = last_variant;
    item.querySelector("input[name*='ware_house']").value = warehouse.name;
    item.querySelector("input[name*='quantity']").value = 0;
    item.querySelector("input[name*='buying_price']").value = 0;
    item.querySelector("input[name*='retail_price']").value = 0;
  }

  checkBuyingPrice() {
    const buyingPrice = parseFloat(this.buyingPriceTarget.value);
    const productType = this.productTypeTarget.value;
    if (productType !== "Service" && buyingPrice === 0) {
      this.buyingPriceErrorTarget.textContent = "Buying price must be greater than 0";
    } else {
      this.buyingPriceErrorTarget.textContent = "";
    }
  }
}
